import { simpleSagaHandlerV2 } from '@frontend/jetlend-core/src/ducks/saga_v2';
import {
    put,
    select,
} from 'redux-saga/effects';
import { simpleFormHandler } from '@frontend/jetlend-core/src/ducks/form';
import {
    requirePhone,
    requireSame,
    required,
} from '@frontend/jetlend-core/src/validators';
import { addToastError } from '@frontend/jetlend-core/src/ducks/toasts';
import {
    CLIENT_TYPE_LOGIN_URLS,
    ClientType,
    CommonStage,
} from '@app/models/common/common';
import { commonLoginStateHandler } from './login';
import {
    ILoginState,
    IPasswordResetFormValues,
} from '@app/models/common/login';
import { signDataWithSmsSaga } from './sms';
import { apiPostPasswordReset } from '@app/services/client/common/loginService';

export const VERSION = 2;
export const PREFIX = 'common/reset';

/**
 * Инициализация визарда сброса пароля пользователя.
 */
export const startResetPasswordSagaHandler = simpleSagaHandlerV2<{
    clientType: ClientType;
}>(PREFIX, 'start_reset_password', function* ({ clientType }) {
    // Initialize state for first screen
    yield put(commonLoginStateHandler.update({
        clientType,
        phone: undefined,
        password: undefined,
        stage: CommonStage.ResetPassword,
        redirectUri: undefined,
    }));

    // Update form for initial values if that exists
    const formValues: IPasswordResetFormValues = {
        clientType,
    };

    yield put(commonPhonePasswordResetFormHandler.formInitialize(formValues));
});

/**
 * Хендлер формы для ввода номера телефона и запроса смс-кода для авторизации на шаг ввода нового пароля.
 */
export const commonPhonePasswordResetFormHandler = simpleFormHandler<IPasswordResetFormValues>(
    PREFIX, 'reset_phone', {
        v2: {
            phone: [ required(), requirePhone() ],
        },
    }, {
        *onBeforeSubmit (values: IPasswordResetFormValues) {
            const {
                clientType,
                phone,
                ...payload
            } = values;

            // Отправляем пользователя на шаг с СМС
            yield put(commonLoginStateHandler.update({
                phone,
                stage: CommonStage.Sms,
            }));

            const smsId = yield signDataWithSmsSaga(clientType, phone, { ...payload }, 'reset-pass');

            // Если пользователь не подписал смс, отправляем обратно на экран ввода номера телефона
            if (!smsId) {
                yield put(commonLoginStateHandler.update({
                    stage: CommonStage.ResetPassword,
                }));

                return undefined;
            }

            // Сохраняем идентификатор и переходим на заполнение нового пароля
            yield put(commonLoginStateHandler.update({
                stage: CommonStage.NewPassword,
                smsId,
            }));
        },
    }, {}
);

/**
 * Хендлер формы для изменения пароля.
 */
export const commonPasswordResetFormHandler = simpleFormHandler<IPasswordResetFormValues>(
    PREFIX, 'pass_reset_form', {
        v2: {
            password1: [ required() ],
            password2: [ required(), requireSame({
                fieldName: 'password1',
                fieldTitle: 'новый пароль',
            }) ],
        },
    }, {
        apiMethod: apiPostPasswordReset,
        *onBeforeSubmit (values: IPasswordResetFormValues) {
            const state: ILoginState = yield select(commonLoginStateHandler.selector);
            const {
                smsId,
                phone,
                clientType,
            } = state;

            return {
                ...values,
                sms_id: smsId,
                phone,
                clientType,
            };
        },
        onSuccess (response, values: IPasswordResetFormValues & { clientType: ClientType }) {
            const { clientType } = values;

            if (clientType) {
                document.location.href = CLIENT_TYPE_LOGIN_URLS[clientType];
            }
        },
        *onFormError (message) {
            yield put(addToastError(message));
        },
    }, {}
);